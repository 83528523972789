body {
    margin: 0;
}

.loader-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        padding-top: 50%;
    }
}

/*see: https://www.w3schools.com/howto/howto_css_loader.asp*/
.loader {
    border: 16px solid #F3F3F3;
    border-top: 16px solid #F3F3F3;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
